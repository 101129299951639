@import 'reset.less';

@colorNote:     #a0eea0;
@spotSize:      80px;
@smallSpotSize: 40px;

html, body {
    margin:      0;
    padding:     0;
    width:       100%;
    height:      100%;
    overflow:    hidden;
    font-family: 'Free Sans', sans-serif;
}
a:visited {
    color: #666;
}
a:link {
    text-decoration: none;
    color:           gray;
}
a:link:hover {
    text-decoration: underline;
    color:           black;
}
#buzzartContainer {
    position:  relative;
    width:     100%;
    max-width: 50em;
    top:       50%;
    margin:    0 auto 0 auto;
    transform: translateY(-50%);
    h1 {
        font-family: Playball, cursive;
        font-size:   12em;
        line-height: 0.55;
        opacity:     0.15;
        padding:     0.1em 0 0.28em 0;
        text-align:  center;
    }
}
#buzzart {
    background:  data-uri('images/quotebegin.png') no-repeat top left;
    display:     block;
    font-family: 'Shadows Into Light Two', cursive;
    box-sizing:  border-box;
    padding:     20px 0 0 39px; // For quote image
    margin-top: -35px;
    p {
        background:     data-uri('images/quoteend.png') no-repeat bottom right;
        font-size:      1.8em;
        letter-spacing: 0.04em;
        margin:         0 0 20px 60px;
        padding:        33px 33px 0 0; // For quote image
        text-indent:    0.35em;

        span.first {
            background:    @colorNote;
            border-radius: 100%;
            box-sizing:    border-box;
            font-size:     50px;
            width:         @spotSize;
            height:        @spotSize;
            left:          -15px;
            top:           10px;
            line-height:   @spotSize;
            position:      absolute;
            text-align:    center;
            z-index:       -1;
        }
    }
    blockquote {
        position: relative;

        footer {
            text-align: right;
            box-sizing: border-box;
            margin-right: 33px;
            margin-left: -39px;
            // &::before {
            //     border-top: 1px dotted gray;
            //     bottom:     50px;
            //     content:    '';
            //     display:    block;
            //     left:       auto;
            //     position:   absolute;
            //     right:      32%;
            //     top:        auto;
            //     width:      20%;
            // }
            .artist {
                width: 50%;
                display: inline-block;
                white-space:    nowrap;
                img {
                    box-sizing:    border-box;
                    border-radius:  100%;
                    vertical-align: middle;
                    float:          right;
                    margin:         10px;
                    opacity:        0.8;
                }
                a {
                    text-decoration: none;
                    color:           gray;
                    display:         inline-block;

                    &::hover {
                        text-decoration: underline;
                        color: #333;
                    }
                    cite {
                        font-family:    'Free Sans', sans-serif;
                        font-style:     normal;
                        letter-spacing: 0.0125em;
                        line-height:    100px;
                    }
                }
            }
        }
    }
}
#by {
    display: block;
    margin-left: 29px;

    a {
        margin: 0 0.25em;
    }
}

button#moreQuotes {
    display:       inline-block;
    box-sizing:    border-box;
    background:    @colorNote;
    border-radius: 100%;
    border:        none;
    box-shadow:    1px 1px 2px rgba(0, 0, 0, 0.4);
    cursor:        pointer;
    font-size:     16px;
    height:        @spotSize;
    line-height:   @spotSize;
    text-align:    center;
    width:         @spotSize;

    &:hover,
    &:active {
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.4) inset;
        background: #90dd90;
        top: 1px;
    }
    &:active {
        box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.9) inset;
    }
    &:focus {
        outline: none;
    }
}

// @media screen and (max-height: 480px) {
//     #buzzart p {
//         font-size: 20px;
//     }
// }
// @media screen and (max-height: 600px) {
//     #buzzartContainer {
//         max-width: 90%;
//         height: 100%;
//         top: 1em;
//     }
//     #buzzart p {
//         padding-right: 0;
//     }
//     h1 {
//         font-size: 9em;
//     }
// }
@media screen and (max-height: 400px) {
    #buzzartContainer {
        height: 100%;
        top:    0.5em;
        transform: none;
        overflow: auto;
        h1 {
            font-size: 8em;
        }
    }
    #by {
        position: relative;
        top:      -1em;
    }
}
@media screen and (max-width: 480px) {
    #buzzartContainer {
        h1 {
            font-size: 5em;
        }
        #buzzart {
            margin-top: 1em;

            p {
                margin:  0;
                padding: 0 23px 0 0;

                span.first {
                    display:  block;
                    float:    left;
                    position: relative;
                    margin: -0.25em 0 0.25em 0;
                }
            }
            footer {
                .artist {
                    width: 100%;
                    img {
                        margin-right: 0;
                    }
                }
           }
        }
    }
}

@media screen and (max-width: 330px) {
    #buzzartContainer {
        position: relative;
        h1 {
            font-size: 3em;
        }
        #by {
            font-size: 10px;
            position:  absolute;
            top:       auto;
            bottom:    1.5em;
        }
        #buzzart {
            margin-top: 0;

            p {
                margin:    0;
                padding:   0 30px 0 0;
                font-size: 16px;

                span.first {
                    display:     block;
                    float:       left;
                    position:    relative;
                    font-weight: bold;
                    text-indent: -0.125em;
                    padding: 0;
                    font-size:   @smallSpotSize * 0.6;
                    width:       @smallSpotSize;
                    height:      @smallSpotSize;
                    line-height: @smallSpotSize + 4px;
                    margin:      -0.75em -0.25em 0.25em 0;
                }
            }
            footer {
                padding: 0;
                margin-right: 1em;
                height: @smallSpotSize;
                line-height: @smallSpotSize * 1.25;

                .artist {
                    cite {
                        line-height: @smallSpotSize;

                        &::before {
                            content: '-';
                            display: inline-block;
                            margin-right: 0.5em;
                        }
                    }
                    img {
                        margin: (@smallSpotSize * 1.25 / 5) 0 0 0.25em;
                        width:        @smallSpotSize;
                        height:       @smallSpotSize;
                    }
                }
                button#moreQuotes {
                    font-size:   @smallSpotSize * 0.3;
                    line-height: @smallSpotSize;
                     width:  @smallSpotSize;
                    height: @smallSpotSize;
                }
             }
        }
    }
}
